<template>
  <contentCard>
    <div class="overflow-scroll">
      <div class="module-title">餐品信息</div>
      <div class="dish-list">
        <div class="dish-item" v-for="(item, index) in dishPacList" :key="'dishItem' + index">
          <div class="dish-picture">
            <img v-if="item.imageUrl" class="img" :src="item.imageUrl" />
            <img v-else class="img" src="./img/zwtp.png" />
          </div>
          <div class="dish-info-box">
            <div class="dish-name-text">{{ ['', void 0, null, NaN].includes(item.dishId) ? item.packageName : item.dishName }}</div>
            <div class="quantity-text">数量 × {{ item.quantity }}</div>
            <div class="price-text"><span>¥</span>{{ item.price }}</div>
          </div>
        </div>
      </div>
      <div class="module-title">配送信息</div>
      <div class="order-form-box">
        <div class="order-form-item">
          <label for="userName">订餐人：</label>
          <input class="order-form-input" type="text" placeholder="请输入订餐人" id="userName" v-model="orderForm.userName" />
        </div>
        <div class="order-form-item">
          <label for="mobileNum">联系电话：</label>
          <input class="order-form-input" type="text" placeholder="请输入联系电话" id="mobileNum" v-model="orderForm.mobileNum" />
        </div>
        <div class="order-form-item" v-if="!noHouseAddress">
          <label for="houseAddress">取餐地址：</label>
          <input
            class="order-form-input have-right-icon"
            type="text"
            placeholder="请选择取餐地址"
            id="houseAddress"
            v-model="orderForm.houseAddress"
            readonly
            @click="houAddPopShow = true"
          />
          <van-icon class="right-icon" name="arrow-down" size="16px" color="#858585" />
        </div>
        <div class="order-form-item">
          <label for="qcsjText">取餐时间：</label>
          <input
            class="order-form-input have-right-icon"
            type="text"
            placeholder="请输入取餐时间"
            id="qcsjText"
            v-model="qcsjText"
            readonly
          />
          <van-icon class="right-icon" name="arrow-down" size="16px" color="#858585" />
        </div>
      </div>
    </div>
    <div class="handle-box">
      <div class="handle qrxd" @click="applyDownOrderOnClick">确认下单</div>
    </div>

    <van-popup v-model="houAddPopShow" round position="bottom" safe-area-inset-bottom>
      <van-picker show-toolbar :columns="houseAddressOptions" @cancel="houAddPopShow = false" @confirm="houAddOnConfirm" />
    </van-popup>
  </contentCard>
</template>

<script>
import storage from '../utils/storage.js';
import contentCard from '../components/contentCard.vue';
import { userInfoURL, savePlaceOrderInfoUrl, getHouseListUrl } from './api.js';
import Big from 'big.js';
import Vue from 'vue';
import { Icon } from 'vant';
import { mapState } from 'vuex';

Vue.use(Icon);

export default {
  name: 'ytjEldCanConfirmPlaceOrder',
  components: {
    contentCard
  },
  data() {
    return {
      dishPacList: [],
      orderForm: {
        tenantId: '',
        userId: '',
        userName: '',
        mobileNum: '',
        spaceId: '',
        spacePath: '',
        houseAddress: '',
        orderDishes: '',
        orderAmount: void 0,
        deliveryTime: null,
        supplyTimeStart: null,
        supplyTimeEnd: null
      },
      houseAddressOptions: ['井亭家园北区南门岗处', '万科金色城市一期东门岗处'],
      houAddPopShow: false,
      popupShow: false,
      noHouseAddress: false
    };
  },
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId']),
    qcsjText() {
      if (this.orderForm.supplyTimeStart && this.orderForm.supplyTimeEnd) {
        return (
          this.orderForm.deliveryTime + ' ' + this.orderForm.supplyTimeStart.slice(0, 5) + '~' + this.orderForm.supplyTimeEnd.slice(0, 5)
        );
      } else {
        return this.orderForm.deliveryTime + ' 全天';
      }
    },
    totalPrice() {
      return this.dishPacList
        .map(item => Big(item.quantity).times(item.price).toNumber())
        .reduce((prev, next) => Big(prev).plus(next).toNumber(), 0);
    }
  },
  created() {
    const dishPacListStr = sessionStorage.getItem('jingting_dining_car_list');
    if (dishPacListStr && dishPacListStr !== 'undefined') {
      this.dishPacList = JSON.parse(dishPacListStr).filter(item => !item.ygdcsdText && item.saleStatus === 1);
      this.orderForm.orderDishes = this.dishPacList
        .map(item =>
          ['', void 0, null, NaN].includes(item.dishId) ? item.packageName + '×' + item.quantity : item.dishName + '×' + item.quantity
        )
        .join('、');
      this.noHouseAddress = this.dishPacList.map(item => item.typeName).includes('午餐');
    }
    const dcsj = sessionStorage.getItem('jingting_dining_car_time');
    this.orderForm.deliveryTime = dcsj;
    const arr = this.dishPacList
      .map(item => [item.supplyTimeStart.slice(11), item.supplyTimeEnd.slice(11)])
      .filter(item => item[0] && item[1])
      .sort((a, b) => new Date(dcsj + ' ' + a[0]).getTime() - new Date(dcsj + ' ' + b[0]).getTime());
    if (arr[0]) {
      this.orderForm.supplyTimeStart = arr[0][0];
      this.orderForm.supplyTimeEnd = arr[0][1];
    }

    this.$axios
      .get(userInfoURL, { params: { userId: this.userId } })
      .then(res => {
        if (res && res.code === 200) {
          this.orderForm.userId = res.data.userId;
          this.orderForm.userName = res.data.userName;
          this.orderForm.mobileNum = res.data.mobile;
          this.orderForm.tenantId = this.tenantId;
          this.orderForm.spaceId = res.data.chosenSpaceId;
          this.orderForm.spacePath = res.data.chosenSpacePath;
        }
      })
      .catch(() => {});
  },
  methods: {
    houAddOnConfirm(value) {
      this.orderForm.houseAddress = value;
      this.houAddPopShow = false;
    },
    applyDownOrderOnClick() {
      const requiredKeys = ['userName', 'mobileNum', 'deliveryTime'];
      this.noHouseAddress === false && requiredKeys.push('houseAddress');
      if (requiredKeys.some(k => ['', void 0, null, NaN].includes(this.orderForm[k]))) {
        this.$toast('请将订单信息填写完整');
        return;
      }
      if (this.dishPacList.length === 0) {
        this.$toast('请添加餐品后下单');
        return;
      }
      if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.orderForm.mobileNum)) {
        this.$toast('请输入正确的联系电话');
        return;
      }
      this.orderForm.orderAmount = this.totalPrice;
      const form = this.$_.cloneDeep(this.orderForm);
      form.orderDetailList = this.dishPacList.map(item => ({
        dishTypeId: item.dishTypeId,
        saleDetailId: item.id,
        dishId: item.dishId,
        dishName: item.dishName,
        packageId: item.packageId,
        packageName: item.packageName,
        price: item.price,
        quantity: item.quantity
      }));
      this.$axios
        .post(savePlaceOrderInfoUrl, form)
        .then(res => {
          if (res && res.code === 200) {
            this.$store.commit('addNoKeepAlive', 'serviceSystem');
            this.$router.replace({
              name: 'ytjEldCanPlaceOrderResult',
              query: { success: 'true', id: res.data }
            });
          } else {
            this.$router.replace({
              name: 'ytjEldCanPlaceOrderResult',
              query: { success: 'false', msg: res.msg }
            });
          }
        })
        .catch(() => {
          this.$router.replace({
            name: 'ytjEldCanPlaceOrderResult',
            query: { success: 'false', msg: '' }
          });
        });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .content-body {
  padding: 0px 40px 30px 40px;
}
.overflow-scroll {
  height: calc(100% - 60px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 100px;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
}
.module-title {
  box-sizing: border-box;
  font-size: 18px;
  height: 25px;
  line-height: 25px;
  color: #2f3a4f;
  font-weight: 600;
}
.dish-list {
  box-sizing: border-box;
  width: 100%;
  height: 110px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  .dish-item {
    box-sizing: border-box;
    flex-shrink: 0;
    margin-right: 20px;
    display: flex;
    height: 100%;
    .dish-picture {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 180px;
      height: 100%;
      border-radius: 4px;
      margin-right: 10px;
      .img {
        box-sizing: border-box;
        border-radius: inherit;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .dish-info-box {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      padding-top: 5px;
      .dish-name-text {
        max-width: 100px;
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
        font-size: 15px;
        line-height: 21px;
        color: #333333;
        margin-bottom: 5px;
      }
      .quantity-text {
        font-size: 13px;
        line-height: 18px;
        color: #333333;
      }
      .price-text {
        font-size: 24px;
        line-height: 33px;
        color: #e02020;
        span {
          font-size: 16px;
        }
      }
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.order-form-box {
  box-sizing: border-box;
  margin-top: 10px;
  // height: calc(100% - (25px + 10px + 110px + 10px + 25px + 10px + 10px + 50px));
  // overflow-x: hidden;
  // overflow-y: auto;
  .order-form-item {
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    padding-bottom: 15px;
    position: relative;
    display: flex;
    label {
      box-sizing: border-box;
      flex-shrink: 0;
      color: #2f3a4f;
      line-height: 50px;
      width: 104px;
    }
    .order-form-input {
      box-sizing: border-box;
      height: 50px;
      border: 1px solid #e4e7ed;
      border-radius: 6px;
      width: calc(100% - 104px);
      line-height: 48px;
      padding: 0px 16px;
      color: #262626;
      &.have-right-icon {
        padding-right: 48px;
      }
    }
    .right-icon {
      position: absolute;
      right: 16px;
      top: 17px;
    }
  }
}
.handle-box {
  box-sizing: border-box;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-top: 10px;
  .handle {
    height: 50px;
    box-sizing: border-box;
    background-color: #1874fd;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    border-radius: 4px;
  }
}
</style>
